.home {
  text-align: center;
  color: #fff;
}

.resumen-variedades {
  background: rgba(0, 0, 0, 0.7);
  padding: 20px;
  margin: 20px;
  border-radius: 10px;
  color: #fff;
}

.navegacion {
  margin-top: 30px;
}

.botones .boton {
  display: inline-block;
  margin: 10px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 5px;
}

.botones .boton:hover {
  background-color: #0056b3;
}

.body {
  background-color: #f8f9fa; /* Color claro para el fondo */
}
