.gestion-plantaciones-container {
    background-image: url('../assets/images/fondo1.jpg'); /* Ruta a tu imagen */
    background-size: cover; /* Asegura que la imagen cubra todo el fondo */
    background-position: center; /* Centra la imagen */
    min-height: 100vh; /* Asegura que el contenedor tenga al menos el tamaño de la ventana */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.gestion-plantaciones-form {
    background-color: rgba(255, 255, 255, 0.9); /* Fondo blanco con un poco de transparencia */
    border-radius: 8px; /* Bordes redondeados */
    padding: 20px; /* Espaciado interno */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Sombra suave */
    width: 100%; /* Ancho del formulario */
    max-width: 450px; /* Ancho máximo reducido */
    margin: 0 auto; /* Centra el formulario en la página */
}

.form-group {
    margin-bottom: 10px; /* Espaciado entre grupos de formulario */
}

.form-icon {
    color: #007bff; /* Cambia el color de los iconos */
    margin-right: 8px; /* Espaciado entre el icono y el input */
}

.gestion-plantaciones-input {
    width: 100%; /* Asegura que los inputs ocupen todo el ancho del formulario */
    padding: 10px; /* Espaciado interno en los inputs */
    border: 1px solid #ccc; /* Borde suave */
    border-radius: 4px; /* Bordes redondeados en los inputs */
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1); /* Sombra interna en los inputs */
}

.gestion-plantaciones-button {
    background-color: #007bff; /* Color de fondo del botón */
    color: white; /* Color del texto del botón */
    padding: 10px 15px; /* Espaciado interno en el botón */
    border: none; /* Sin borde */
    border-radius: 4px; /* Bordes redondeados en el botón */
    cursor: pointer; /* Cambia el cursor al pasar sobre el botón */
}

.gestion-plantaciones-button:hover {
    background-color: #0056b3; /* Color más oscuro al pasar el cursor sobre el botón */
}
