/* Centra el formulario y ajusta el tamaño máximo */
.form-container {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    background-color: rgba(255, 255, 255, 0.8); /* Transparente para ver fondo */
}

/* Título centrado y espaciado */
h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
    font-weight: 600;
}

/* Grupo de formularios de Bootstrap */
.form-group {
    margin-bottom: 15px;
}

/* Etiquetas de campos */
label {
    font-weight: 600;
    margin-bottom: 5px;
    color: #555;
}

/* Campos de entrada */
input[type="text"],
input[type="number"],
input[type="date"],
textarea {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
    width: 100%;
}

/* Área de texto con altura predeterminada */
textarea {
    height: 100px;
}

/* Estilo del botón de envío */
.submit-button {
    background-color: #007bff; /* Color Bootstrap primary */
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    transition: background-color 0.3s ease;
}

.submit-button:hover {
    background-color: #0056b3; /* Tonalidad más oscura al pasar el cursor */
}
