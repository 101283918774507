.card {
    border: none;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.9); /* Fondo semitransparente */
}

.card-title {
    font-weight: bold;
    color: #333;
}

.card-text {
    color: #555;
}
