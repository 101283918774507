.registro-actividad-container {
    /*background-image: url('../assets/images/fondo2.jpeg'); /* Asegúrate de que la ruta sea correcta */
    background-size: cover; /* La imagen cubre todo el fondo */
    background-position: center; /* Centra la imagen */
    min-height: 100vh; /* Asegura que el contenedor tenga al menos el tamaño de la ventana */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.registro-actividad-form-container {
    background-color: rgba(255, 255, 255, 0.8); /* Fondo blanco con un poco de transparencia */
    border-radius: 8px; /* Bordes redondeados */
    padding: 20px; /* Espaciado interno */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Sombra suave */
    width: 100%;
    max-width: 450px; /* Ancho máximo para el formulario */
    margin: 20px auto; /* Centramos el formulario */
}
