.home-container {
  background-image: url('../assets/images/fondo1.jpg');
  background-size: contain;  /* Ajusta la imagen para que se vea completa */
  background-position: center; /* Centra la imagen */
  min-height: 100vh; /* Hace que el contenedor ocupe toda la altura de la pantalla */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  color: white;
}

.home-content {
  background-color: rgba(0, 0, 0, 0.6); /* Fondo oscuro semitransparente */
  padding: 20px;
  border-radius: 10px;
}

h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #ffffff;
}

p {
  font-size: 1.1em;
  color: #ffffff;
}

.carousel-content {
  background-color: rgba(0, 0, 0, 0.5); /* Fondo semitransparente para el carrusel */
  padding: 20px;
  color: white;
  border-radius: 10px;
  text-align: left;
}

.carousel-image {
  width: 300px; /* Ajusta el tamaño según lo necesites */
  height: auto;
  transition: transform 0.5s ease; /* Efecto de transición suave */
}

.carousel-item.active .carousel-image {
  transform: scale(1.2); /* Aumenta el tamaño de la imagen activa */
}

.card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
  background-color: rgba(255, 255, 255, 0.8); /* Fondo blanco semitransparente */
  transition: background-color 0.3s ease; /* Transición suave al pasar el mouse */
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  background-color: rgba(255, 255, 255, 0.9); /* Aumentar opacidad al pasar el mouse */
}

.card-title {
  font-weight: bold;
}

.card-body {
  background-color: rgba(255, 255, 255, 0.85); /* Fondo claro semitransparente */
  border-radius: 5px;
}

.card-text {
  color: #555; /* Color del texto de las tarjetas */
}

.module-card {
  background-color: rgba(255, 255, 255, 0.8); /* Fondo blanco semitransparente */
  transition: transform 0.3s;
}

.module-card:hover {
  transform: scale(1.05);
}

.recommendation-card {
  background-color: rgba(255, 255, 255, 0.9); /* Fondo blanco semitransparente */
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  margin: 0 auto;
}

